import { Visit } from "@cur8/rich-entity";
import {
  VisitStatus,
  fetchPatientVisitCandidates,
  validateVisitWithinBounds,
} from "lib/api/booking/visit";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { useVisit } from "render/hooks/useVisit";
import { paths } from "render/routes/paths";
import { BailView } from "../BailView/BailView";
import { ConfirmedView } from "./components/ConfirmedView";
import { OutOfRangeView } from "./components/OutOfRangeView";
import { VisitNotFoundView } from "./components/VisitNotFoundView";
import { MemberJoinedView } from "./components/WaitingForVisitView";

class VisitNotFoundError extends Error {}

interface SessionJoinCompleteViewProps {
  goTo(url: string): void;
  onBack(): void;
}

export function SessionJoinCompleteView({
  goTo,
  onBack,
}: SessionJoinCompleteViewProps) {
  const api = useAPIClient();

  const { state, update } = useConciergeSessionContext();
  const { patient, visit } = state;
  const checkedInVisit = visit;

  const { checkIn } = useVisit();

  const [error, setError] = useState<Error>();

  const completeCheckIn = useCallback(
    async (target: Visit) => {
      const result = await checkIn(target);
      update({ visit: result });
    },
    [checkIn, update]
  );

  const handleContinue = useCallback(async () => {
    if (visit && visit.checkinStartTimestamp == null) {
      await completeCheckIn(visit);
    }

    const url = paths.awaitingConsent.url({});
    goTo(url);
  }, [goTo, visit, completeCheckIn]);

  useEffect(() => {
    if (!patient || visit) {
      return;
    }

    const now = DateTime.now();

    fetchPatientVisitCandidates(api, patient.patientId, now).then((visits) => {
      if (visits.length === 0) {
        setError(new VisitNotFoundError("No visits for period"));
        return;
      }
      const target = visits[0];

      update({ visit: target });
    });
  }, [api, patient, update, visit]);

  if (!patient) {
    return <BailView message="No member" />;
  }

  if (error instanceof VisitNotFoundError) {
    return <VisitNotFoundView />;
  }

  if (!checkedInVisit) {
    return <MemberJoinedView />;
  }

  const status = validateVisitWithinBounds(checkedInVisit);
  if (status === VisitStatus.Valid) {
    return (
      <ConfirmedView
        onContinue={handleContinue}
        onBack={onBack}
        visit={checkedInVisit}
      />
    );
  }

  return (
    <OutOfRangeView
      status={status}
      visit={checkedInVisit}
      onContinue={handleContinue}
    />
  );
}
