import { APITypesV2 } from "@cur8/api-client";
import { useLegalDocumentQuery } from "hooks/queries/useLegalDocumentQuery";
import { useLatestConsentsQuery } from "hooks/queries/usePatientConsentsQuery";
import { parseDocument } from "lib/legal/parseDocument";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "render/views/BailView";
import styles from "./styles.module.sass";
import { useIsLatestQuestionnaireCompleteQuery } from "hooks/queries/useQuestionnaireQuery";
import { Typography } from "@cur8/maneki";
import { List } from "render/ui/presentation/List";
import React from "react";
import { ReactComponent as Checkmark } from "render/assets/icons/16x16/16x16_checkmark.svg";

const POLL_INTERVAL = 5000;

interface ChecklistViewProps {
  goTo(url: string): void;
  onBack(): void;
}

function SignatureConsentItem({
  consent,
  signature,
}: {
  consent: APITypesV2.ConsentMetadata;
  signature: APITypesV2.ConsentSummary | undefined;
}) {
  const { data: legalDoc } = useLegalDocumentQuery(
    consent.documents.find((document) => document.language === "en")?.uri
  );

  if (!legalDoc) return;

  const { title } = parseDocument(legalDoc);
  const checkedStatusLabel = getStatus(signature);
  const checked = getIsSigned(signature);

  return (
    <List.Item>
      <div className={styles.questionnaireItem}>
        <div className={styles.itemTitle}>
          <Typography variant="label-m">{title}</Typography>
          {consent.optional ? (
            <Typography variant="body-m" color="subtle">
              (Optional)
            </Typography>
          ) : undefined}
        </div>
        <StatusBadge
          checked={checked}
          checkedStatusLabel={checkedStatusLabel}
          uncheckedStatusLabel="Not signed"
        />
      </div>
    </List.Item>
  );
}

function getIsSigned(signature: APITypesV2.ConsentSummary | undefined) {
  if (signature) {
    if (signature.optedOut) {
      return true;
    }

    if (!signature.expired && !signature.revoked) {
      return true;
    }
  }

  return false;
}

function getStatus(signature: APITypesV2.ConsentSummary | undefined) {
  if (signature?.optedOut) {
    return "Opted out";
  }

  return "Signed";
}

export function ChecklistView({ goTo, onBack }: ChecklistViewProps) {
  const {
    state: { patient, visit },
  } = useConciergeSessionContext();
  const [canProceed, setCanProceed] = useState(false);
  const [pollConsents, setPollConsents] = useState(true);
  const [pollQuestionnaire, setPollQuestionnaire] = useState(true);

  const isVisitInSweden = visit?.siteId.startsWith("SE-");

  const handleContinue = useCallback(() => {
    const url = isVisitInSweden
      ? paths.medicalJournalConsent.url({})
      : paths.preferredName.url({});
    goTo(url);
  }, [goTo, isVisitInSweden]);

  const { data: polledConsents } = useLatestConsentsQuery({
    patientId: patient?.patientId,
    options: {
      enabled: pollConsents,
      refetchInterval: POLL_INTERVAL,
    },
  });

  const { data: hasCompletedLatestQuestionnaire } =
    useIsLatestQuestionnaireCompleteQuery({
      patientId: patient?.patientId,
      visitId: visit?.visitId,
      options: {
        enabled: pollQuestionnaire,
        refetchInterval: POLL_INTERVAL,
      },
    });

  const signatures = useMemo(
    () =>
      new Map(
        polledConsents
          ?.filter((c) => !c.relevantSignature.expired)
          .map((c) => [c.consentMetadata.consentId, c.relevantSignature])
      ),
    [polledConsents]
  );

  const duringVisitConsents = visit?.consents
    ?.filter(
      (c) =>
        c.signatureType === APITypesV2.ConsentSignatureType.Self &&
        c.displayStages.includes(APITypesV2.ConsentDisplayStage.DuringVisit)
    )
    .sort((a) =>
      // studies first
      a.consentType === APITypesV2.ConsentType.Study ? -1 : 1
    );

  useEffect(() => {
    if (!polledConsents || !hasCompletedLatestQuestionnaire) return;

    const completedAllConsents = (duringVisitConsents || []).every((c) =>
      getIsSigned(signatures.get(c.consentId))
    );

    setPollConsents(!completedAllConsents);
    setPollQuestionnaire(!hasCompletedLatestQuestionnaire);
    setCanProceed(completedAllConsents && hasCompletedLatestQuestionnaire);
  }, [
    duringVisitConsents,
    signatures,
    polledConsents,
    hasCompletedLatestQuestionnaire,
  ]);

  if (!patient) {
    return <BailView title="No Member Selected" />;
  }

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <section className={styles.layout}>
            <div className={styles.title}>
              <Typography variant="display-s">Almost there...</Typography>
              <Typography variant="body-m" color="subtle">
                Please ask the member to complete the steps on their phone to
                continue.
              </Typography>
            </div>

            <List>
              {hasCompletedLatestQuestionnaire != null ? (
                <List.Item>
                  <div className={styles.questionnaireItem}>
                    <div className={styles.itemTitle}>
                      <Typography variant="label-m">Questionnaire</Typography>
                    </div>
                    <StatusBadge
                      checked={hasCompletedLatestQuestionnaire}
                      checkedStatusLabel="Completed"
                      uncheckedStatusLabel="Incomplete"
                    />
                  </div>
                </List.Item>
              ) : undefined}
              {duringVisitConsents?.map((consent) => (
                <React.Fragment key={consent.consentId}>
                  <List.Divider />
                  <SignatureConsentItem
                    consent={consent}
                    signature={signatures.get(consent.consentId)}
                  />
                </React.Fragment>
              ))}
            </List>
          </section>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
                hideIcon
              >
                Back
              </ActionButton>
            }
            right={
              <ActionButton
                variant="suggestion"
                disabled={!canProceed}
                onClick={handleContinue}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}

function StatusBadge({
  checked,
  checkedStatusLabel,
  uncheckedStatusLabel,
}: StatusBadgeProps) {
  const statusLabel = checked ? checkedStatusLabel : uncheckedStatusLabel;
  return (
    <div className={styles.status} data-checked={checked}>
      {checked && <Checkmark />}
      <Typography variant="eyebrow-m" as="span">
        {statusLabel}
      </Typography>
    </div>
  );
}

type StatusBadgeProps = {
  checked: boolean | undefined;
  checkedStatusLabel?: string;
  uncheckedStatusLabel?: string;
};
