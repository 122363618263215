import { ReactComponent as CheckMark } from "render/assets/icons/check/check-28x28.svg";
import { ReactComponent as ExMark } from "render/assets/icons/ex.svg";
import styles from "./styles.module.sass";
import { Typography } from "@cur8/maneki";

interface BinaryRadioProps {
  label: string;
  name: string;
  value?: boolean;
  error?: string;
  onChange: (value: boolean) => void;
}

export function BinaryRadio({
  label,
  onChange,
  value,
  name,
  error,
}: BinaryRadioProps) {
  return (
    <div className={styles.inputContainer}>
      <input
        id={`${name}-true`}
        data-testid={`${name}-true`}
        type="radio"
        name={name}
        checked={value === true}
        value="true"
        onChange={() => onChange(true)}
      />
      <label htmlFor={`${name}-true`}>
        <CheckMark />
      </label>
      <div className={styles.descriptionContainer}>
        <Typography variant="label-m">{label}</Typography>
        {error && (
          <Typography variant="body-xs" color="warning">
            {error}
          </Typography>
        )}
      </div>
      <input
        id={`${name}-false`}
        data-testid={`${name}-false`}
        type="radio"
        name={name}
        value="false"
        checked={value === false}
        onChange={() => onChange(false)}
      />
      <label htmlFor={`${name}-false`}>
        <ExMark />
      </label>
    </div>
  );
}
