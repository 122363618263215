import {
  RadioGroupProps,
  Radio as ReactAriaRadio,
  RadioGroup as ReactAriaRadioGroup,
} from "react-aria-components";
import { Typography } from "@cur8/maneki";
import styles from "./styles.module.sass";

type RadioGroupPropsType = {
  children: React.ReactNode;
} & RadioGroupProps;

export function RadioGroup({ children, ...props }: RadioGroupPropsType) {
  return (
    <ReactAriaRadioGroup {...props} className={styles.RadioGroup}>
      {children}
    </ReactAriaRadioGroup>
  );
}

RadioGroup.Radio = Radio;

function Radio({
  value,
  label,
  topRight,
  bottomRight,
  bottomLeft,
}: {
  value: string;
  label: React.ReactNode;
  topRight?: React.ReactNode;
  bottomLeft?: React.ReactNode;
  bottomRight?: React.ReactNode;
}) {
  return (
    <ReactAriaRadio value={value} className={styles.Radio}>
      <div className={styles.checkboxIcon} />
      <div className={styles.textWrapper}>
        <div className={styles.textRowWrapper}>
          <Typography variant="label-m">{label}</Typography>
          {topRight ? (
            <Typography variant="body-m" color="subtle" whiteSpace="nowrap">
              {topRight}
            </Typography>
          ) : undefined}
        </div>
        <div className={styles.textRowWrapper}>
          {bottomLeft ? (
            <Typography variant="body-s" color="subtle">
              {bottomLeft}
            </Typography>
          ) : undefined}
          {bottomRight ? (
            <Typography variant="body-s" color="subtle">
              {bottomRight}
            </Typography>
          ) : undefined}
        </div>
      </div>
    </ReactAriaRadio>
  );
}
