import React from "react";
import { ReactComponent as Checkmark } from "render/assets/icons/24x24/24x24_checkmark.svg";
import styles from "./styles.module.sass";
import { Typography } from "@cur8/maneki";

interface ChecklistItemProps {
  checked?: boolean;
  children: React.ReactNode;
}

export function ChecklistItem({ checked, children }: ChecklistItemProps) {
  return (
    <div className={styles.ChecklistItem} data-checked={checked}>
      <div className={styles.icon}>
        <Checkmark />
      </div>
      <div className={styles.content}>
        <Typography as="span" variant="body-m">
          {children}
        </Typography>
      </div>
    </div>
  );
}
