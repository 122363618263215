import React from "react";
import { ReactComponent as NekoLogo } from "./assets/nekoLogo.svg";
import styles from "./styles.module.sass";

interface LogoHeaderProps {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
}

export function LogoHeader({ leftElement, rightElement }: LogoHeaderProps) {
  return (
    <div className={styles.header}>
      <div className={styles.left}>{leftElement}</div>
      <div className={styles.centre}>
        <NekoLogo />
      </div>
      <div className={styles.right}>{rightElement}</div>
    </div>
  );
}
