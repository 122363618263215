import styles from "./styles.module.sass";

export function List({ children }: { children: React.ReactNode }) {
  return <ul className={styles.List}>{children}</ul>;
}

List.Item = ListItem;
List.Divider = ListDivider;

type ListItemProps = {
  children: React.ReactNode;
};

function ListItem({ children }: ListItemProps) {
  return <li className={styles.ListItem}>{children}</li>;
}

function ListDivider() {
  return <div className={styles.divider} />;
}
