import { useFinishCheckInMutation } from "hooks/mutations/useFinishCheckInMutation";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "render/views/BailView";
import { useRoomQuery } from "hooks/queries/useRoomQuery";
import { Typography } from "@cur8/maneki";
import { getAndParseLocationCookie } from "lib/nekoCookies";
import { useFetchEndCheckInJobQuery } from "hooks/queries/useFetchEndCheckInJobQuery";
import { useEffect, useState } from "react";
import { APITypesV1 } from "@cur8/api-client";
import { useReporting } from "render/hooks/useReporting";

interface AssignToRoomProps {
  goTo(url: string): void;
}

export function AssignToRoomView({ goTo }: AssignToRoomProps) {
  const {
    state: { patient, visit },
  } = useConciergeSessionContext();
  const [jobId, setJobId] = useState<string | undefined>(undefined);

  const { logError } = useReporting();

  const roomQuery = useRoomQuery({ roomId: visit?.roomId });
  const roomName = roomQuery.data?.displayName;

  const finishCheckIn = useFinishCheckInMutation();
  const fetchEndCheckInJobQuery = useFetchEndCheckInJobQuery({
    jobId,
    patientId: patient?.patientId,
    visitId: visit?.visitId,
    options: {
      enabled: Boolean(jobId),
      refetchInterval: 1000,
    },
  });

  const location = getAndParseLocationCookie();
  const isRegeringsgatan = location?.siteId === "SE-ARN10";

  const nextUrl = isRegeringsgatan
    ? paths.checkInSuccess.url({})
    : paths.assignToSkinScanner.url({});

  useEffect(() => {
    if (fetchEndCheckInJobQuery.data?.state === APITypesV1.JobState.Complete) {
      goTo(nextUrl);
      setJobId(undefined);
    }
    if (fetchEndCheckInJobQuery.data?.state === APITypesV1.JobState.Failed) {
      logError("Assign to room job failed");
      setJobId(undefined);
    }
    if (fetchEndCheckInJobQuery.error) {
      logError(fetchEndCheckInJobQuery.error);
    }
  }, [
    fetchEndCheckInJobQuery.data?.state,
    fetchEndCheckInJobQuery.error,
    goTo,
    logError,
    nextUrl,
  ]);

  if (!patient) {
    return <BailView title="No Member" />;
  }

  if (!visit) {
    return <BailView title="No Visit object" />;
  }

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <>
            <Typography variant="display-s">Are you in {roomName}?</Typography>
            <Typography variant="body-m" color="subtle">
              When {patient.name?.displayName || "the member"} is ready to start
              the scan, assign them to the room.
            </Typography>
          </>
        }
        buttons={
          <ActionsFooter
            right={
              <ActionButton
                variant="suggestion"
                busy={finishCheckIn.isPending || Boolean(jobId)}
                onClick={async () => {
                  if (finishCheckIn.isPending) return;
                  finishCheckIn.mutate(visit, {
                    onSuccess: (data) => setJobId(data.jobId),
                    onError: (error) =>
                      logError(`Failed to create assign to room job: ${error}`),
                  });
                }}
              >
                Yes, assign to room
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
