import React from "react";
import styles from "./styles.module.sass";

export function Busy(props: React.SVGAttributes<unknown>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#019DC9"
      {...props}
    >
      <circle className={styles.topleft} cx="18" cy="18" r="4" />
      <circle className={styles.topright} cx="18" cy="18" r="4" />
      <circle className={styles.bottomleft} cx="18" cy="18" r="4" />
      <circle className={styles.bottomright} cx="18" cy="18" r="4" />
    </svg>
  );
}
