import { Visit } from "@cur8/rich-entity";
import { useMutation } from "@tanstack/react-query";
import { useAPIClient } from "render/context/APIContext";

export function useStartCheckInMutation() {
  const apiClient = useAPIClient();

  return useMutation({
    mutationFn({ patientId, visitId }: Pick<Visit, "visitId" | "patientId">) {
      return apiClient.visit.startCheckInProcess({ patientId, visitId }).result;
    },
  });
}
