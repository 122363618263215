import { fromAPI } from "@cur8/rich-entity";
import { useQueries, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";

export function useVisitQueries(
  data:
    | Array<{ visitId: string | undefined; patientId: string | undefined }>
    | undefined
) {
  const apiClient = useAPIClient();

  return useQueries({
    queries: data
      ? data.map(({ visitId, patientId }) => {
          return {
            queryKey: queryKey(patientId, visitId),
            queryFn: queryFn(apiClient, patientId, visitId),
          };
        })
      : [],
  });
}

export function useVisitQuery({
  patientId,
  visitId,
}: {
  patientId: string | undefined;
  visitId: string | undefined;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(patientId, visitId),
    queryFn: queryFn(apiClient, patientId, visitId),
  });
}

function queryFn(
  apiClient: APIClient,
  patientId: string | undefined,
  visitId: string | undefined
) {
  return async function () {
    if (patientId == null) {
      return Promise.reject("patientId is undefined");
    }
    if (visitId == null) {
      return Promise.reject("visitId is undefined");
    }

    const visitDTO = await apiClient.visit.fetchVisit({
      patientId,
      visitId,
    }).result;

    const result = fromAPI.toVisit(visitDTO);

    return result;
  };
}

function queryKey(patientId: string | undefined, visitId: string | undefined) {
  return ["visit", patientId, visitId] as const;
}
