import { Answer, Question } from "@cur8/questionnaire";
import React from "react";
import { StepView } from "render/views/IncidentReportView/components/StepView";

interface QuestionViewProps {
  question: Question<unknown, React.ReactNode>;
  answer?: Answer;
  optional?: boolean;
  onPrev?: () => void;
  onNext: () => void;
  children: React.ReactNode;
  cta?: React.ReactElement;
}

export function QuestionView({
  question,
  children,
  cta,
  onPrev,
}: QuestionViewProps) {
  return (
    <StepView caption={question.statement} onPrev={onPrev} cta={cta}>
      {children}
    </StepView>
  );
}
