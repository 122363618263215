import { VisitSummary } from "@cur8/rich-entity";
import { useMemo } from "react";
import styles from "./styles.module.sass";
import { Typography } from "@cur8/maneki";

interface PatientDetails {
  name?: string;
  visits?: VisitSummary[];
}

export function PatientDetails({ name, visits }: PatientDetails) {
  const visitType = useMemo(
    () => (visits ? (visits.length > 0 ? "Follow-up" : "Baseline") : null),
    [visits]
  );

  return (
    <>
      {name && visitType && (
        <div className={styles.PatientDetails}>
          <Typography variant="label-s">{name}</Typography>
          <Typography variant="body-s" color="subtle">
            {visitType}
          </Typography>
        </div>
      )}
    </>
  );
}
