import { fromAPI } from "@cur8/rich-entity";
import { skipToken, useQueries, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";

export function useRoomQueries(data: Array<string | undefined> | undefined) {
  const apiClient = useAPIClient();

  return useQueries({
    queries: data
      ? data.map((roomId) => {
          return {
            queryKey: queryKey(roomId),
            queryFn: queryFn(apiClient, roomId),
          };
        })
      : [],
  });
}

export function useRoomQuery({ roomId }: { roomId: string | undefined }) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(roomId),
    queryFn: roomId ? queryFn(apiClient, roomId) : skipToken,
    staleTime: Infinity,
  });
}

const queryKey = (roomId: string | undefined) => ["room", roomId];
const queryFn =
  (apiClient: APIClient, roomId: string | undefined) => async () => {
    if (roomId == null) {
      return Promise.reject("No roomId");
    }
    const roomDTO = await apiClient.schedule.fetchRoom({ roomId }).result;
    const result = fromAPI.toRoom(roomDTO);

    return result;
  };
