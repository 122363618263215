import "./index.css";
import "./init";

import { getConfig } from "auth/config";
import { getInsights } from "auth/insights";
import { getMSAL } from "auth/msal";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App.tsx";

Promise.resolve()
  .then(async () => {
    const config = await getConfig();
    const [appInsights, msal] = await Promise.all([
      getInsights(config),
      getMSAL(config),
    ]);
    return { config, msal, appInsights };
  })
  .then(({ config, appInsights, msal }) => {
    const element = document.getElementById("root");
    if (element) {
      const root = createRoot(element);
      root.render(
        <StrictMode>
          <App config={config} appInsights={appInsights} msal={msal} />
        </StrictMode>
      );
    }
  });
