import { AuthenticationResult } from "@azure/msal-browser";
import { UserURI } from "@cur8/uri";
import { ReactComponent as ArrowIcon } from "render/assets/icons/arrow2.svg";
import { OperatorAvatar } from "render/ui/layout/HeaderRightSideContent/OperatorAvatar";
import { DropdownMenuButton } from "render/ui/trigger/DropdownMenuButton/DropdownMenuButton";
import { DropdownItem } from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/DropdownItem";
import styles from "./styles.module.sass";
import { Typography } from "@cur8/maneki";
import { getAndParseLocationCookie } from "lib/nekoCookies";

interface OperatorDetailsDropdownItemProps {
  name?: string;
  userURI: UserURI;
}

const OperatorDetailsDropdownItem = ({
  name,
  userURI,
}: OperatorDetailsDropdownItemProps) => (
  <div className={styles.OperatorDetailsDropdownItem}>
    <OperatorAvatar uri={userURI} size="large" />
    <div className={styles.OperatorDetailsDropdownItemContainer}>
      <Typography variant="label-s">{name}</Typography>
    </div>
  </div>
);

interface OperatorHeaderItemProps {
  auth: AuthenticationResult;
  onSignOutClick?: () => void;
}

export function OperatorHeaderItem({
  auth,
  onSignOutClick,
}: OperatorHeaderItemProps) {
  const location = getAndParseLocationCookie();

  return (
    <DropdownMenuButton
      direction="right"
      cta={({ onClick, open }) => (
        <div
          onClick={onClick}
          className={styles.AvatarContainer}
          data-open={open}
        >
          <OperatorAvatar uri={new UserURI(auth.tenantId, auth.uniqueId)} />
          {location != null ? (
            <Typography variant="body-s" color="subtle">
              {location.name}, {location.cityCode}
            </Typography>
          ) : undefined}
        </div>
      )}
    >
      <OperatorDetailsDropdownItem
        name={auth.account?.name}
        userURI={new UserURI(auth.tenantId, auth.uniqueId)}
      />
      <DropdownItem icon={<ArrowIcon />} onClick={onSignOutClick}>
        Sign out
      </DropdownItem>
    </DropdownMenuButton>
  );
}
