/* eslint-disable @typescript-eslint/no-explicit-any */
export function isInTestMode() {
  const url = new URL(window.location.href);
  const hasTestModeParam = (window as any)["neko"]?.isTestMode === true;

  const isLocalHost = url.host === "localhost:3002";
  const result = hasTestModeParam && isLocalHost;

  return result;
}
