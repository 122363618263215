import { Answer, Answers, Question } from "@cur8/questionnaire";
import { useCallback, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useReporting } from "render/hooks/useReporting";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { Questionnaire } from "render/ui/presentation/Questionnaire";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "render/views/BailView";
import { questionnaireToReport } from "render/views/IncidentReportView/questionnaireToReport";
import {
  DeviceList,
  HasAdverseEvent,
  HasDeviceIssue,
} from "render/views/IncidentReportView/questions";

import {
  DeviceListStep,
  HasAdverseEventStep,
  HasDeviceIssueStep,
  MitigationActionStep,
  OutcomeStep,
  RelationshipToDeviceStep,
  RelationshipToProcedureStep,
  SeverityGradeStep,
  SuccessStep,
  getDeviceDeficiencyStepsForDevice,
} from "render/views/IncidentReportView/steps";
import { Typography } from "@cur8/maneki";

export interface StepProps {
  answers: Answers;
  onAnswer: (question: Question, answer: Answer) => void;
  onResetAnswer: (question: Question) => void;
  onPrev: () => void;
  onNext: () => void;
  currentQuestion: number;
  totalQuestions: number;
  isSubmitting: boolean;
  isActive: boolean;
}

export type Step = (props: StepProps) => React.ReactElement;

export function getSteps(answer: Answers) {
  const steps: Step[] = [HasDeviceIssueStep];

  const hasDeviceIssue = answer.lookup(HasDeviceIssue);
  if (hasDeviceIssue) {
    steps.push(DeviceListStep);

    const answerDeviceList = answer.lookup(DeviceList);
    if (hasDeviceIssue && answerDeviceList) {
      for (const device of answerDeviceList) {
        const deviceSteps = getDeviceDeficiencyStepsForDevice(device);
        steps.push(...deviceSteps);
      }
    }
  }

  steps.push(HasAdverseEventStep);

  const hasAdverseEvent = answer.lookup(HasAdverseEvent);
  if (hasAdverseEvent) {
    steps.push(
      SeverityGradeStep,
      RelationshipToDeviceStep,
      RelationshipToProcedureStep,
      MitigationActionStep,
      OutcomeStep
    );
  }

  steps.push(SuccessStep);

  return steps;
}

export function IncidentReportView({
  goTo,
  activeStep,
}: {
  activeStep: number;
  goTo: (url: string) => void;
}) {
  const { logError } = useReporting();
  const {
    state: { visit },
  } = useConciergeSessionContext();
  const [answers, setAnswers] = useState<Answers>(() => new Answers());
  const api = useAPIClient();
  const [error, setError] = useState<string | undefined>();

  const handleComplete = useCallback(async () => {
    try {
      if (!visit) throw new Error("Missing visit");
      setError(undefined);
      const report = questionnaireToReport(
        answers,
        visit.visitId,
        visit.patientId
      );

      return await api.patient.sendIncidentReport(
        visit.patientId,
        visit.visitId,
        report
      ).result;
    } catch (err) {
      setError("Couldn't send incident report.");
      logError(err);
    }
  }, [answers, api.patient, logError, visit]);

  const completeHandle = useAsyncHandle(handleComplete);

  const onBack = () => {
    goToStep(-1);
  };

  const goToStep = useCallback(
    (offset: number) => {
      const url = paths.incidentReport.url({
        activeStep: activeStep + offset,
      });
      goTo(url);
    },
    [activeStep, goTo]
  );

  const enabledSteps: Step[] = useMemo(() => getSteps(answers), [answers]);

  const onNext = useCallback(async () => {
    const isLastStep = activeStep === enabledSteps.length - 2; // last step is just a success screen
    if (isLastStep) {
      await completeHandle.callback();
    }

    goToStep(1);
  }, [activeStep, completeHandle, enabledSteps, goToStep]);

  const handleAnswer = useCallback((question: Question, answer: Answer) => {
    setAnswers((answers) => answers.set(question, answer));
  }, []);

  const resetAnswer = useCallback((question: Question) => {
    setAnswers((answers) => answers.remove(question));
  }, []);

  if (!visit) {
    return <BailView title="No visit found" />;
  }

  if (activeStep < 0 || activeStep >= enabledSteps.length) {
    return <BailView title="Wrong incident report state" />;
  }

  if (error) {
    return (
      <PageFrameContent>
        <InformationLayout
          content={
            <>
              <Typography variant="display-s">An error occurred</Typography>
              <Typography variant="body-m" color="subtle">
                {error}
              </Typography>
            </>
          }
          buttons={
            <ActionsFooter
              right={
                <ActionButton
                  onClick={completeHandle.callback}
                  busy={completeHandle.busy}
                  variant="suggestion"
                >
                  Retry
                </ActionButton>
              }
            />
          }
        />
      </PageFrameContent>
    );
  }

  return (
    <PageFrameContent>
      <Questionnaire activeIndex={activeStep}>
        {enabledSteps.map((Step, index) => (
          <Step
            isSubmitting={completeHandle.busy}
            currentQuestion={index + 1}
            totalQuestions={enabledSteps.length - 1}
            key={index}
            answers={answers}
            onAnswer={handleAnswer}
            onResetAnswer={resetAnswer}
            onPrev={onBack}
            onNext={onNext}
            isActive={index === activeStep}
          />
        ))}
      </Questionnaire>
    </PageFrameContent>
  );
}
