import { useMSAL } from "render/context/MSALContext";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { Typography } from "@cur8/maneki";
import styles from "./styles.module.sass";
import { RadioGroup } from "render/ui/presentation/RadioGroup";
import { useSitesQuery } from "hooks/queries/useSitesQuery";
import { useMemo, useState } from "react";
import { ActionButton } from "render/ui/trigger/ActionButton";
import Cookies from "js-cookie";
import { paths } from "render/routes/paths";
import { LocationType, NEKO_COOKIES } from "lib/nekoCookies";
import { InformationLayout } from "render/ui/layout/InformationLayout";

export function LocationSelectorView({
  goTo,
}: {
  goTo: (url: string) => void;
}) {
  const { auth } = useMSAL();
  const firstName = auth?.account?.name?.split(" ")[0];

  const sitesQuery = useSitesQuery({});
  // useful in dev to remove the hundreds of integration test sites
  // const sitesData = sitesQuery?.data?.items.slice(0, 4);
  const sitesData = sitesQuery?.data?.items;
  const sites: LocationType[] | undefined = sitesData?.map((site) => {
    return {
      value: site.siteId,
      label: site.siteName,
      subline: site.address.street,
      metadata: cityMapper(site.address.state),
    };
  });

  const [selected, setSelected] = useState<string | undefined>(undefined);
  const selectedSite = useMemo(() => {
    return sites?.find((site) => site.value === selected);
  }, [selected, sites]);

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <section className={styles.layout}>
            <div className={styles.heading}>
              <Typography variant="display-s">Welcome, {firstName}</Typography>
              <Typography variant="body-m" color="subtle">
                Please select your location below
              </Typography>
            </div>
            <RadioGroup
              aria-label="Select site location"
              onChange={(value) => setSelected(value)}
            >
              {sites?.map((site) => {
                return (
                  <RadioGroup.Radio
                    key={site.value}
                    value={site.value}
                    label={site.label}
                    bottomLeft={site.subline}
                    topRight={site.metadata}
                  />
                );
              })}
            </RadioGroup>
          </section>
        }
        buttons={
          <ActionButton
            variant="suggestion"
            disabled={!selected}
            onClick={() => {
              selectedSite &&
                Cookies.set(
                  NEKO_COOKIES.settings.location.v1,
                  JSON.stringify(selectedSite),
                  { expires: 400 }
                );
              goTo(paths.root.url({}));
            }}
          >
            <Typography variant="label-m">Continue</Typography>
          </ActionButton>
        }
      />
    </PageFrameContent>
  );
}

function cityMapper(city: string) {
  switch (city) {
    case "London":
      return "LDN";
    case "Stockholm":
      return "STO";
    default:
      return "";
  }
}
