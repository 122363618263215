import { useMutation } from "@tanstack/react-query";
import { useAPIClient } from "render/context/APIContext";
import { ImmediateRiskAssessment } from "lib/types/immediate-risk-assessment";
import { v4 } from "uuid";
import { APITypesV2 } from "@cur8/api-client";
import { QuestionnaireLanguageCode } from "lib/questionnaire/locale";

type ImmediateRiskAssessmentStoreProps = {
  patientId: string | undefined;
  visitId: string | undefined;
  assessment: ImmediateRiskAssessment;
};

export function useImmediateRiskMutation() {
  const apiClient = useAPIClient();

  return useMutation({
    mutationFn({
      patientId,
      visitId,
      assessment,
    }: ImmediateRiskAssessmentStoreProps) {
      if (patientId == null) {
        return Promise.reject("No patientId");
      }

      const responseId = v4();
      const questionnaire: APITypesV2.QuestionnaireResponse = {
        questionnaireType: "immediate-risk-assessment/1",
        patientId,
        state: APITypesV2.QuestionnaireResponseState.Complete,
        responseId,
        visitId,
        questionsAndAnswers: [
          {
            questionId: "accuteSymptoms",
            languageCode: QuestionnaireLanguageCode.En,
            label: "Does the member have acute symptoms today?",
            answer: {
              $type: "boolean",
              value: assessment.acuteSymptoms ?? false,
            },
          },
          {
            questionId: "eyeSurgery",
            languageCode: QuestionnaireLanguageCode.En,
            label: "Eye surgery performed in the last week?",
            answer: {
              $type: "boolean",
              value: assessment.eyeSurgery ?? false,
            },
          },
          {
            questionId: "medicationUsageProneToBleeding",
            languageCode: QuestionnaireLanguageCode.En,
            label: "Medication usage predisposing to bleeding?",
            answer: {
              $type: "boolean",
              value: assessment.medicationUsageProneToBleeding ?? false,
            },
          },
        ],
      };

      return apiClient.questionnaire.createOrUpdateQuestionnaireResponse(
        { patientId, responseId },
        questionnaire
      ).result;
    },
    onError: (error) => alert(error),
  });
}
