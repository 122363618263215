import { createChoiceAnswer } from "@cur8/questionnaire";
import { useEffect } from "react";
import { GradeInput } from "render/ui/presentation/Questionnaire/components/GradeInput";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { QuestionView } from "render/views/IncidentReportView/components/QuestionView";
import { StepProps } from "render/views/IncidentReportView/IncidentReportView";
import { RelationshipToDevice } from "render/views/IncidentReportView/questions";

export function RelationshipToDeviceStep({
  answers,
  onAnswer,
  onPrev,
  onNext,
  onResetAnswer,
  isActive,
}: StepProps) {
  const answer = answers.get(RelationshipToDevice);
  const response = answers.lookup(RelationshipToDevice);

  useEffect(() => {
    if (isActive && !response) {
      onAnswer(RelationshipToDevice, createChoiceAnswer(0));
    }
  }, [isActive, onAnswer, response]);

  return (
    <QuestionView
      question={RelationshipToDevice}
      answer={answer}
      cta={
        <ActionButton
          disabled={!response}
          onClick={onNext}
          variant="suggestion"
        >
          Continue
        </ActionButton>
      }
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(RelationshipToDevice);
      }}
    >
      <GradeInput
        question={RelationshipToDevice}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(RelationshipToDevice, answer);
        }}
      />
    </QuestionView>
  );
}
