import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ThreeDotsIcon } from "./assets/3-dot.svg";
import styles from "./styles.module.sass";

interface Props {
  cta?: (props: { open: boolean; onClick: () => void }) => React.ReactNode;
  children: React.ReactNode;
  direction?: "left" | "right";
}

export function DropdownMenuButton({
  cta,
  children,
  direction = "left",
}: Props) {
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuExpanded, setMenuExpanded] = useState<boolean>(false);
  const toggleDropdownMenu = useCallback(() => {
    setMenuExpanded((menuExpanded) => !menuExpanded);
  }, []);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMenuExpanded(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, menuRef]);

  return (
    <div className={styles.DropdownMenuButton} ref={menuRef}>
      {cta ? (
        <div className={styles.cta}>
          {cta({ open: menuExpanded, onClick: toggleDropdownMenu })}
        </div>
      ) : (
        <div
          className={styles.cta}
          onClick={toggleDropdownMenu}
          data-expanded={menuExpanded}
        >
          <div className={styles.icon}>
            <ThreeDotsIcon />
          </div>
        </div>
      )}
      <div
        className={styles.DropDownMenu}
        data-direction={direction}
        data-expanded={menuExpanded}
        onClick={toggleDropdownMenu}
      >
        <div className={styles.menuItems}>{children}</div>
      </div>
    </div>
  );
}
