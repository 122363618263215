import { MultiChoiceInput } from "render/ui/presentation/Questionnaire/components/MultiChoiceInput";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { StepProps } from "render/views/IncidentReportView/IncidentReportView";
import { DeviceList } from "render/views/IncidentReportView/questions";
import { QuestionView } from "render/views/IncidentReportView/components/QuestionView";

export function DeviceListStep({
  answers,
  onAnswer,
  onNext,
  onResetAnswer,
  onPrev,
}: StepProps) {
  const answer = answers.get(DeviceList);
  const selectedItems = answers.lookup(DeviceList);

  return (
    <QuestionView
      question={DeviceList}
      answer={answer}
      cta={
        <ActionButton
          disabled={!selectedItems || selectedItems?.length === 0}
          onClick={onNext}
          variant="suggestion"
        >
          Continue
        </ActionButton>
      }
      onNext={() => {
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(DeviceList);
      }}
    >
      <MultiChoiceInput
        question={DeviceList}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(DeviceList, answer);
        }}
      />
    </QuestionView>
  );
}
