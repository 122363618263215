import { useCallback, useState, useRef } from "react";

/*
Returns a handle for a callback that keeps track of busy state
and prevents hammering while busy in a consistent way.
*/
export function useAsyncHandle<T, A extends unknown[], P extends Promise<T>>(
  resolver: (...args: A) => P
) {
  const [promise, setPromise] = useState<P>();
  const inFlight = useRef<boolean>(false);

  const callback = useCallback(
    (...args: A): P | undefined => {
      if (inFlight.current === true) {
        return;
      }

      inFlight.current = true;
      const promise = resolver(...args);
      setPromise(promise);

      return promise.finally(() => {
        inFlight.current = false;
        setPromise(undefined);
      }) as P;
    },
    [resolver]
  );

  return {
    busy: !!promise,
    promise,
    callback,
    run: callback,
  };
}
