import { codecs, createPath } from "@pomle/paths";

const root = createPath("/", {});

const locationSelector = root.append("/location-selector", {});

const selectVisit = root.append("/select-visit", {});

const selectActiveVisit = root.append("/select-active-visit", {});

const selectVisitConfirm = selectVisit.append("/confirm/:patientId/:visitId", {
  patientId: codecs.string,
  visitId: codecs.string,
});

const checkIn = root.append("/check-in", {});

const assignSlot = checkIn.append("/assign-slot", {});

const awaitingConsent = checkIn.append("/awaiting-consent", {});

const counterSign = checkIn.append("/counter-sign-consent", {});

const medicalJournalConsent = checkIn.append("/medical-journal-consent", {});

const preferredName = checkIn.append("/preferred-name", {});

const immediateRiskAssessment = checkIn.append(
  "/immediate-risk-assessment",
  {}
);

const assignToRoom = checkIn.append("/assign-to-room", {});

const assignToSkinScanner = checkIn.append("/assign-to-skin-scanner", {});

const checkInSuccess = checkIn.append("/success", {});

const checkOut = root.append("/checkout", {});

const incidentReport = root.append("/incident-report/:activeStep", {
  activeStep: codecs.number,
});

export const paths = {
  assignToRoom,
  assignToSkinScanner,
  checkInSuccess,
  root,
  awaitingConsent,
  counterSign,
  medicalJournalConsent,
  assignSlot,
  preferredName,
  immediateRiskAssessment,
  checkOut,
  incidentReport,
  locationSelector,
  selectVisit,
  selectActiveVisit,
  selectVisitConfirm,
};
