import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

type EndCheckInJobProps = {
  jobId: string | undefined;
  patientId: string | undefined;
  visitId: string | undefined;
};

export function useFetchEndCheckInJobQuery<T = Body>({
  jobId,
  patientId,
  visitId,
  options = {},
}: {
  options?: QueryOptions<Body, Key, T>;
} & EndCheckInJobProps) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey({ jobId, patientId, visitId }),
    queryFn:
      jobId && patientId && visitId
        ? queryFn(apiClient, { jobId, patientId, visitId })
        : skipToken,
    ...options,
  });
}

const queryKey = ({ jobId, patientId, visitId }: EndCheckInJobProps) => [
  "endCheckInJob",
  jobId,
  patientId,
  visitId,
];

const queryFn =
  (
    apiClient: APIClient,
    { jobId, patientId, visitId }: StrictRequired<EndCheckInJobProps>
  ) =>
  async () => {
    return await apiClient.visit.fetchEndCheckInJob({
      jobId,
      patientId,
      visitId,
    }).result;
  };

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

// Utility type to make all properties required and exclude undefined
type StrictRequired<T> = {
  [P in keyof T]-?: Exclude<T[P], undefined>;
};
