import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { Typography } from "@cur8/maneki";
import styles from "./styles.module.sass";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { paths } from "render/routes/paths";
import { getAndParseLocationCookie } from "lib/nekoCookies";
import { useVisitQuery } from "hooks/queries/useVisitQuery";
import { usePatientQuery } from "hooks/queries/usePatientQuery";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { useRoomQuery } from "hooks/queries/useRoomQuery";
import { useStartCheckInMutation } from "hooks/mutations/useStartCheckInMutation";
import { AppointmentCard } from "render/ui/presentation/AppointmentCard";
import { InformationLayout } from "render/ui/layout/InformationLayout";

export function SelectVisitConfirmView({
  goTo,
  onBack,
  patientId,
  visitId,
}: {
  goTo: (url: string) => void;
  onBack(): void;
  patientId: string;
  visitId: string;
}) {
  const { update } = useConciergeSessionContext();
  const location = getAndParseLocationCookie();
  const siteId = location?.siteId;

  const visitQuery = useVisitQuery({ patientId, visitId });
  const patientQuery = usePatientQuery({ patientId });
  const member = patientQuery.data;
  const visit = visitQuery.data;
  const roomQuery = useRoomQuery({ roomId: visit?.roomId });
  const room = roomQuery.data;

  const fullName = `${member?.name?.firstName} ${member?.name?.lastName}`;
  const dob = member?.dateOfBirth?.toISODate();
  const time = `${visit?.startTime.toFormat(
    "HH:mm"
  )} - ${visit?.endTime.toFormat("HH:mm")}`;
  const roomName = room?.displayName;

  const startCheckInMutation = useStartCheckInMutation();

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <section className={styles.layout}>
            <div className={styles.title}>
              <Typography variant="display-s">Confirm check-in</Typography>
              <Typography variant="body-m" color="subtle">
                Confirm that this is the correct member.
              </Typography>
            </div>

            <AppointmentCard
              topLeft={fullName}
              topRight={time}
              bottomLeft={dob}
              bottomRight={roomName}
            />
          </section>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
                hideIcon
              >
                Back
              </ActionButton>
            }
            right={
              <ActionButton
                onClick={() => {
                  update({
                    visit: visitQuery.data,
                    patient: patientQuery.data,
                  });
                  startCheckInMutation.mutate({ patientId, visitId });
                  const url = siteId?.startsWith("SE-")
                    ? paths.medicalJournalConsent.url({})
                    : paths.preferredName.url({});
                  goTo(url);
                }}
                variant="suggestion"
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
