import { useEffect, useState } from "react";
import styles from "./styles.module.sass";

export function SpinningLoader({ success }: { success: boolean }) {
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (success === false && done === true) {
      setDone(false);
    }
  }, [done, success]);

  return (
    <svg className={styles.circular} viewBox="25 25 50 50" data-done={success}>
      <circle
        className={styles.path}
        data-done={done}
        cx="50"
        cy="50"
        r="20"
        onAnimationIteration={() => {
          if (success) {
            setDone(true);
          }
        }}
      />
    </svg>
  );
}
