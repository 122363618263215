import { ActionButton } from "render/ui/trigger/ActionButton/ActionButton";
import styles from "./styles.module.sass";

interface ActionsFooterProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export function ActionsFooter({
  left,
  right = (
    <ActionButton variant="suggestion" disabled>
      Continue
    </ActionButton>
  ),
}: ActionsFooterProps) {
  return (
    <div className={styles.ActionsFooter}>
      {left}
      {right}
    </div>
  );
}
