import { fromAPI } from "@cur8/rich-entity";
import { useMutation } from "@tanstack/react-query";
import { useAPIClient } from "render/context/APIContext";

export function useCreateSessionMutation() {
  const apiClient = useAPIClient();

  return useMutation({
    mutationFn(seed: string) {
      return apiClient.checkinSession
        .createSession({ seed })
        .result.then(fromAPI.toCheckinSessionState);
    },
  });
}
