import { RadioChoiceInput } from "render/ui/presentation/Questionnaire/components/RadioChoiceInput";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { StepProps } from "render/views/IncidentReportView/IncidentReportView";
import { WhatWasTheOutcome } from "render/views/IncidentReportView/questions";
import { QuestionView } from "render/views/IncidentReportView/components/QuestionView";

export function OutcomeStep({
  answers,
  onAnswer,
  onPrev,
  onNext,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(WhatWasTheOutcome);
  const response = answers.lookup(WhatWasTheOutcome);

  return (
    <QuestionView
      question={WhatWasTheOutcome}
      answer={answer}
      cta={
        <ActionButton
          disabled={!response}
          onClick={onNext}
          variant="suggestion"
        >
          Continue
        </ActionButton>
      }
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(WhatWasTheOutcome);
      }}
    >
      <RadioChoiceInput
        name="outcome"
        answer={answer}
        question={WhatWasTheOutcome}
        onAnswer={(answer) => {
          onAnswer(WhatWasTheOutcome, answer);
        }}
      />
    </QuestionView>
  );
}
