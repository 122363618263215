import {
  Link as ReactAriaLink,
  LinkProps as ReactAriaLinkProps,
} from "react-aria-components";
import styles from "./styles.module.sass";

type LinkProps = {
  children: React.ReactNode;
} & Omit<ReactAriaLinkProps, "children">;

export function Link(props: LinkProps) {
  return (
    <ReactAriaLink {...props} className={`${props.className} ${styles.Link}`}>
      {props.children}
    </ReactAriaLink>
  );
}
