import { APITypesV2 } from "@cur8/api-client";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string | undefined) => [
  "questionnaires",
  patientId,
];
const queryFn =
  (
    apiClient: APIClient,
    patientId: string | undefined,
    visitId: string | undefined
  ) =>
  async () => {
    if (patientId == null) {
      return Promise.reject("No patientId");
    }
    if (visitId == null) {
      return Promise.reject("No visitId");
    }
    return apiClient.questionnaire.getQuestionnaireResponses(
      {
        patientId,
      },
      { visitId }
    ).result;
  };

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

function useQuestionnaireQuery<T = Body>({
  patientId,
  visitId,
  options = {},
}: {
  patientId: string | undefined;
  visitId: string | undefined;
  options?: QueryOptions<Body, Key, T>;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(patientId),
    queryFn: queryFn(apiClient, patientId, visitId),
    staleTime: Infinity,
    enabled:
      Boolean(patientId) && Boolean(visitId) && (options.enabled ?? true),
    ...options,
  });
}

export function useIsLatestQuestionnaireCompleteQuery<T = Body>({
  patientId,
  visitId,
  options,
}: {
  patientId: string | undefined;
  visitId: string | undefined;
  options: Omit<QueryOptions<Body, Key, T>, "select">;
}) {
  return useQuestionnaireQuery({
    patientId,
    visitId,
    options: {
      ...options,
      select: (data) => {
        const latestQuestionnaire = data.items.at(0);
        if (latestQuestionnaire == null) {
          return false;
        }
        return (
          latestQuestionnaire.state ===
          APITypesV2.QuestionnaireResponseState.Complete
        );
      },
    },
  });
}

useQuestionnaireQuery.queryKey = queryKey;
useQuestionnaireQuery.queryFn = queryFn;
