import { Typography } from "@cur8/maneki";
import styles from "./styles.module.sass";

export function AppointmentCard({
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
}: {
  topLeft: string;
  topRight: string;
  bottomLeft: string | null | undefined;
  bottomRight: string | undefined;
}) {
  return (
    <section className={styles.AppointmentCard}>
      <div className={styles.row}>
        <Typography variant="label-m">{topLeft}</Typography>
        <Typography variant="body-m" color="subtle">
          {topRight}
        </Typography>
      </div>
      <div className={styles.row}>
        <Typography variant="body-m" color="subtle">
          {bottomLeft}
        </Typography>
        <Typography variant="body-m" color="subtle">
          {bottomRight}
        </Typography>
      </div>
    </section>
  );
}
