import {
  ApplicationInsights,
  DistributedTracingModes,
} from "@microsoft/applicationinsights-web";
import type { Config } from "auth/config";

export async function getInsights(config: Config) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: config.appInsights.connectionString,
      distributedTracingMode: DistributedTracingModes.W3C,
      disableFetchTracking: false,
      enableCorsCorrelation: true,
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: false,
      enableUnhandledPromiseRejectionTracking: true,
    },
  });
  appInsights.loadAppInsights();
  appInsights.addTelemetryInitializer((envelope) => {
    if (envelope.data) {
      envelope.data.appName = "ConciergeUI";
      envelope.data.appVersion = import.meta.env.REACT_APP_VERSION;
      envelope.data.origin = window.location.origin;
      envelope.data.href = window.location.href;
    }
  });

  return appInsights;
}
