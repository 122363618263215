import { APITypesV1 } from "@cur8/api-client";
import { TextQuestion } from "@cur8/questionnaire";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { StepProps } from "render/views/IncidentReportView/IncidentReportView";
import {
  BloodSystemIssueDescription,
  CardioLasersIssueDescription,
  CardioRadarIssueDescription,
  DermaAppIssueDescription,
  DocUIIssueDescription,
  ECGIssueDescription,
  EyePressureIssueDescription,
  GripStrengthIssueDescription,
  MESIIssueDescription,
  POXIssueDescription,
  ScaleIssueDescription,
  SkinSystemIssueDescription,
  TissueSystemIssueDescription,
} from "render/views/IncidentReportView/questions";
import { QuestionView } from "render/views/IncidentReportView/components/QuestionView";

export const DeviceDescriptionQuestionMap: Record<
  APITypesV1.DeviceName,
  TextQuestion<string>
> = {
  [APITypesV1.DeviceName.CardioLasers]: CardioLasersIssueDescription,
  [APITypesV1.DeviceName.CardioRadar]: CardioRadarIssueDescription,
  [APITypesV1.DeviceName.CardioSystem]: CardioRadarIssueDescription,
  [APITypesV1.DeviceName.Ecg]: ECGIssueDescription,
  [APITypesV1.DeviceName.MesiSystem]: MESIIssueDescription,
  [APITypesV1.DeviceName.BloodSystem]: BloodSystemIssueDescription,
  [APITypesV1.DeviceName.DermaApp]: DermaAppIssueDescription,
  [APITypesV1.DeviceName.DocUI]: DocUIIssueDescription,
  [APITypesV1.DeviceName.EyePressure]: EyePressureIssueDescription,
  [APITypesV1.DeviceName.GripStrength]: GripStrengthIssueDescription,
  [APITypesV1.DeviceName.Pox]: POXIssueDescription,
  [APITypesV1.DeviceName.TissueSystem]: TissueSystemIssueDescription,
  [APITypesV1.DeviceName.SkinSystem]: SkinSystemIssueDescription,
  [APITypesV1.DeviceName.Scale]: ScaleIssueDescription,
};

export const createDeviceIssueDescriptionStep = (
  device: APITypesV1.DeviceName
) => {
  const question = DeviceDescriptionQuestionMap[device];
  if (!question) throw new Error(`No question for device ${device}`);

  return function ({
    answers,
    onAnswer,
    onNext,
    onPrev,
    onResetAnswer,
    isActive,
  }: StepProps) {
    const answer = answers.get(question);
    const response = answers.lookup(question);

    return (
      <QuestionView
        question={question}
        answer={answer}
        cta={
          <ActionButton
            onClick={onNext}
            disabled={!response}
            variant="suggestion"
          >
            Continue
          </ActionButton>
        }
        onNext={onNext}
        onPrev={() => {
          onPrev();
          onResetAnswer(question);
        }}
      >
        <TextInput
          placeholder="Describe the issue"
          answer={answer}
          onAnswer={(answer) => {
            onAnswer(question, answer);
          }}
          autoFocus={isActive}
        />
      </QuestionView>
    );
  };
};
