import { useNav } from "@pomle/react-router-paths";
import { useCallback } from "react";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { paths } from "render/routes/paths";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Typography } from "@cur8/maneki";

export function VisitNotFoundView() {
  const { reset } = useConciergeSessionContext();

  const nav = {
    start: useNav(paths.root),
  };

  const handleRestart = useCallback(() => {
    nav.start.go({});
    reset();
  }, [nav.start, reset]);

  return (
    <PageFrameContent>
      <InformationLayout
        align="start"
        content={
          <>
            <Typography variant="display-s">Appointment not found</Typography>
            <Typography variant="body-m" color="subtle">
              We couldn't find the member's appointment today. Please check the
              schedule and try again.
            </Typography>
          </>
        }
        buttons={
          <ActionButton
            variant="secondary"
            direction="backward-centered"
            onClick={handleRestart}
            hideIcon
          >
            Back
          </ActionButton>
        }
      />
    </PageFrameContent>
  );
}
