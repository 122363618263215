import React from "react";
import { ReactComponent as ArrowIcon } from "render/assets/icons/arrow.svg";
import { Busy } from "./components/Busy";
import styles from "./styles.module.sass";
import { Typography } from "@cur8/maneki";

type Variant = "primary" | "danger" | "secondary" | "suggestion" | "wallet";

interface ActionButtonProps extends React.ButtonHTMLAttributes<unknown> {
  busy?: boolean;
  variant?: Variant;
  hideIcon?: boolean;
  direction?: "forward" | "backward" | "backward-centered";
}

export function ActionButton({
  variant = "primary",
  busy = false,
  children,
  hideIcon,
  direction = "forward",
  ...props
}: ActionButtonProps) {
  return (
    <button
      {...props}
      className={styles.button}
      data-direction={direction}
      data-busy={busy}
      data-variant={variant}
      data-icons={!hideIcon}
    >
      {children ? (
        <Typography variant="label-m" as="span" whiteSpace="nowrap">
          {children}
        </Typography>
      ) : undefined}

      <div className={styles.icons}>
        <Busy className={styles.busy} />
        <ArrowIcon className={styles.leftArrow} />
        <ArrowIcon className={styles.rightArrow} />
      </div>
    </button>
  );
}
