import { fromAPI } from "@cur8/rich-entity";
import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

export function useFetchSessionQuery<T = Body>({
  sessionId,
  options = {},
}: {
  sessionId: string | undefined;
  options?: QueryOptions<Body, Key, T>;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(sessionId),
    queryFn: sessionId ? queryFn(apiClient, sessionId) : skipToken,
    ...options,
  });
}

const queryKey = (sessionId: string | undefined) => ["session", sessionId];
const queryFn = (apiClient: APIClient, sessionId: string) => async () => {
  const response = await apiClient.checkinSession.fetchSession({ sessionId })
    .result;
  return fromAPI.toCheckinSessionState(response);
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;
