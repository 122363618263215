import { useMutation } from "@tanstack/react-query";
import { useAPIClient } from "render/context/APIContext";

export function useAssignSkinScannerMutation() {
  const apiClient = useAPIClient();

  return useMutation({
    mutationFn({ patientId, visitId }: { patientId: string; visitId: string }) {
      return apiClient.visit.assignSkinScanner({ patientId, visitId }).result;
    },
  });
}
