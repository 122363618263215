import { createInternationalizationContext } from "@pomle/react-node-i18n";

export enum Locale {
  enGB = "en_GB",
  svSE = "sv_SE",
}

const { localize, InternationalizationProvider } =
  createInternationalizationContext([Locale.enGB, Locale.svSE]);

export { localize, InternationalizationProvider };
