import { APITypesV1 } from "@cur8/api-client";
import { APIClient } from "lib/api/client";

function fetchStudiesExhaustively(
  api: APIClient,
  params: APITypesV1.PatientConsentQueryParams
) {
  function fetchPage(params: APITypesV1.PatientConsentQueryParams) {
    return api.consent.getListOfConsents({
      ...params,
    }).result;
  }

  async function fetchAll(params: APITypesV1.PatientConsentQueryParams) {
    const buffer: APITypesV1.ConsentSummary[] = [];

    let nextPage: string | undefined;
    do {
      const res = await fetchPage({
        ...params,
        continuationToken: nextPage,
      });
      buffer.push(...res.items);
      nextPage = res.nextPage;
    } while (nextPage);

    return buffer;
  }

  return fetchAll(params);
}

function fetchActiveConsentSummaries(api: APIClient, patientId: string) {
  return fetchStudiesExhaustively(api, {
    patientId,
    validityState: APITypesV1.ConsentValidityState.WithinValidityRange,
  });
}

export async function fetchActiveConsents(api: APIClient, patientId: string) {
  const summaries = await fetchActiveConsentSummaries(api, patientId);

  const consents = await Promise.all(
    summaries.map((summary) => {
      return fetchConsent(api, patientId, summary.id);
    })
  );

  return consents;
}

function fetchConsent(api: APIClient, patientId: string, consentId: string) {
  return api.consent.getConsent({ patientId, consentId }).result;
}
