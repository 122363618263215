import { Typography } from "@cur8/maneki";
import styles from "./styles.module.sass";

type LoginOptionProps = Omit<JSX.IntrinsicElements["input"], "type">;

export function LoginOption({ children, ...props }: LoginOptionProps) {
  return (
    <label className={styles.LoginOption}>
      <input {...props} type="radio" />
      <div className={styles.checkboxIcon} />
      <div className={styles.label}>
        <Typography variant="label-m">{children}</Typography>
      </div>
    </label>
  );
}
