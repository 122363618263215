import { APITypesV1 } from "@cur8/api-client";
import { createDeviceIssueDescriptionStep } from "render/views/IncidentReportView/steps/DeviceIssueDescriptionStep";
import { createDeviceIssueDetailsStep } from "render/views/IncidentReportView/steps/DeviceIssueDetailsStep";

const EcgIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.Ecg
);
const CardioLasersIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.CardioLasers
);
const CardioRadarIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.CardioRadar
);
const MESIIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.MesiSystem
);

const EcgIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.Ecg
);
const CardioLasersIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.CardioLasers
);

const CardioRadarIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.CardioRadar
);
const MESIIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.MesiSystem
);

const BloodSystemIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.BloodSystem
);
const BloodSystemIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.BloodSystem
);

const DermaAppIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.DermaApp
);
const DermaAppIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.DermaApp
);

const DocUIIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.DocUI
);
const DocUIIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.DocUI
);

const EyePressureIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.EyePressure
);
const EyePressureIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.EyePressure
);

const GripStrengthIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.GripStrength
);
const GripStrengthIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.GripStrength
);

const POXIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.Pox
);
const POXIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.Pox
);

const TissueSystemIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.TissueSystem
);
const TissueSystemIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.TissueSystem
);

const SkinSystemIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.SkinSystem
);
const SkinSystemIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.SkinSystem
);

const ScaleIssueStep = createDeviceIssueDescriptionStep(
  APITypesV1.DeviceName.Scale
);
const ScaleIssueDetailsStep = createDeviceIssueDetailsStep(
  APITypesV1.DeviceName.Scale
);

export { DeviceListStep } from "./DeviceListStep";
export { HasAdverseEventStep } from "./HasAdverseEventsStep";
export { HasDeviceIssueStep } from "./HasDeviceIssueStep";
export { MitigationActionStep } from "./MitigationActionStep";
export { OutcomeStep } from "./OutcomeStep";
export { RelationshipToDeviceStep } from "./RelationshipToDeviceStep";
export { RelationshipToProcedureStep } from "./RelationshipToProcedureStep";
export { SeverityGradeStep } from "./SeverityGradeStep";
export { SuccessStep } from "./SuccessStep";

export function getDeviceDeficiencyStepsForDevice(
  device: APITypesV1.DeviceName
) {
  switch (device) {
    case APITypesV1.DeviceName.Ecg:
      return [EcgIssueStep, EcgIssueDetailsStep];
    case APITypesV1.DeviceName.CardioLasers:
      return [CardioLasersIssueStep, CardioLasersIssueDetailsStep];
    case APITypesV1.DeviceName.CardioRadar:
      return [CardioRadarIssueStep, CardioRadarIssueDetailsStep];
    case APITypesV1.DeviceName.MesiSystem:
      return [MESIIssueStep, MESIIssueDetailsStep];
    case APITypesV1.DeviceName.BloodSystem:
      return [BloodSystemIssueStep, BloodSystemIssueDetailsStep];
    case APITypesV1.DeviceName.DermaApp:
      return [DermaAppIssueStep, DermaAppIssueDetailsStep];
    case APITypesV1.DeviceName.DocUI:
      return [DocUIIssueStep, DocUIIssueDetailsStep];
    case APITypesV1.DeviceName.EyePressure:
      return [EyePressureIssueStep, EyePressureIssueDetailsStep];
    case APITypesV1.DeviceName.GripStrength:
      return [GripStrengthIssueStep, GripStrengthIssueDetailsStep];
    case APITypesV1.DeviceName.Pox:
      return [POXIssueStep, POXIssueDetailsStep];
    case APITypesV1.DeviceName.TissueSystem:
      return [TissueSystemIssueStep, TissueSystemIssueDetailsStep];
    case APITypesV1.DeviceName.SkinSystem:
      return [SkinSystemIssueStep, SkinSystemIssueDetailsStep];
    case APITypesV1.DeviceName.Scale:
      return [ScaleIssueStep, ScaleIssueDetailsStep];
    default:
      throw new Error(`Unknown device ${device}`);
  }
}
