import { Configuration as MSALConfiguration } from "@azure/msal-browser";

type AppInsights = {
  connectionString: string;
};

type MSALScopes = {
  scopes: {
    login: string[];
    token: string[];
  };
};

type MSAL = MSALConfiguration & MSALScopes;

export type Config = {
  apiBaseUrl: string;
  msal: MSAL;
  appInsights: AppInsights;
  appConfig: {
    patientAppBaseUrl: string;
    legalApiUrl: string;
    roomAllowedList?: string[];
  };
};

export function getConfig() {
  return fetch("/config.json")
    .then<Config>((response) => response.json())
    .catch((error) => {
      console.error("Config init failed: %s", error);
      throw error;
    });
}
