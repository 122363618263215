import { record, string } from "@pomle/shapes";
import Cookies from "js-cookie";

export const NEKO_COOKIES = {
  settings: {
    location: {
      v1: "neko_settings_location_1",
    },
  },
};

export type LocationType = {
  value: string;
  label: string;
  subline: string;
  metadata: string;
};

type ParsedLocation = {
  siteId: string;
  name: string;
  address: string;
  cityCode: string;
};

export function extendLocationCookie(parsedLocation: ParsedLocation) {
  const location: LocationType = {
    value: parsedLocation.siteId,
    label: parsedLocation.name,
    subline: parsedLocation.address,
    metadata: parsedLocation.cityCode,
  };
  return Cookies.set(
    NEKO_COOKIES.settings.location.v1,
    JSON.stringify(location),
    { expires: 400 }
  );
}

export function getAndParseLocationCookie() {
  return parseLocationCookie(Cookies.get(NEKO_COOKIES.settings.location.v1));
}

function parseLocationCookie(
  cookieValue: string | undefined
): ParsedLocation | undefined {
  let location;
  try {
    location = cookieValue && validate(JSON.parse(cookieValue));
  } catch (error) {
    console.error("Error parsing or validating the location cookie", error);
    return undefined;
  }

  return location
    ? {
        siteId: location?.value,
        name: location?.label,
        address: location?.subline,
        cityCode: location?.metadata,
      }
    : undefined;
}

const validate = record<LocationType>({
  value: string(""),
  label: string(""),
  subline: string(""),
  metadata: string(""),
});
