import { Question } from "@cur8/questionnaire";
import { PropsWithChildren } from "react";
import styles from "./styles.module.sass";
import { Typography } from "@cur8/maneki";

interface QuestionGroupProps {
  question: Question<unknown, React.ReactNode>;
}

export function QuestionGroup({
  question,
  children,
}: PropsWithChildren<QuestionGroupProps>) {
  return (
    <div className={styles.QuestionGroup}>
      <Typography variant="title-m">{question.statement}</Typography>
      {children}
    </div>
  );
}
