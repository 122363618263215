import { useNav } from "@pomle/react-router-paths";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "render/views/BailView";
import { Typography } from "@cur8/maneki";

interface SuccessStepProps {
  isSubmitting: boolean;
}

export function SuccessStep({ isSubmitting }: SuccessStepProps) {
  const {
    state: { visit },
  } = useConciergeSessionContext();

  const nav = {
    start: useNav(paths.root),
  };

  const { reset } = useConciergeSessionContext();

  const handleContinue = () => {
    nav.start.go({});
    reset();
  };

  if (!visit) {
    return <BailView title="No visit" />;
  }

  if (isSubmitting) {
    return (
      <PageFrameContent>
        <InformationLayout
          content={
            <>
              <Typography variant="display-s">Submitting</Typography>
              <Typography variant="body-m" color="subtle">
                Please wait while we submit your report
              </Typography>
            </>
          }
        />
      </PageFrameContent>
    );
  }

  return (
    <PageFrameContent>
      <InformationLayout
        buttons={
          <ActionsFooter
            right={
              <ActionButton onClick={handleContinue} variant="suggestion">
                Continue
              </ActionButton>
            }
          />
        }
        content={
          <>
            <Typography variant="display-s">
              Thank you for your report
            </Typography>
          </>
        }
      />
    </PageFrameContent>
  );
}
