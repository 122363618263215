import { Visit } from "@cur8/rich-entity";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Typography } from "@cur8/maneki";
import { useRoomQuery } from "hooks/queries/useRoomQuery";
import { usePatientQuery } from "hooks/queries/usePatientQuery";
import { AppointmentCard } from "render/ui/presentation/AppointmentCard";
import styles from "./styles.module.sass";

interface ConfirmedViewProps {
  visit: Visit;
  onContinue(): Promise<unknown>;
  onBack(): void;
}

export function ConfirmedView({
  visit,
  onContinue,
  onBack,
}: ConfirmedViewProps) {
  const handle = useAsyncHandle(onContinue);

  const formattedVisitStart = visit.startTime.toFormat("HH:mm");

  const roomQuery = useRoomQuery({ roomId: visit?.roomId });
  const room = roomQuery.data;
  const patientQuery = usePatientQuery({ patientId: visit.patientId });
  const member = patientQuery.data;

  const fullName = `${member?.name?.firstName} ${member?.name?.lastName}`;
  const visitTime = `${formattedVisitStart} - ${visit?.endTime.toFormat(
    "HH:mm"
  )}`;
  const roomName = room?.displayName;
  const dob = member?.dateOfBirth?.toISODate();

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <section className={styles.layout}>
            <div className={styles.title}>
              <Typography variant="display-s">Confirm check-in</Typography>
              <Typography variant="body-m" color="subtle">
                Confirm that this is the correct member
              </Typography>
            </div>
            <AppointmentCard
              topLeft={fullName}
              topRight={visitTime}
              bottomLeft={dob}
              bottomRight={roomName}
            />
          </section>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
                hideIcon
              >
                Back
              </ActionButton>
            }
            right={
              <ActionButton
                variant="suggestion"
                busy={handle.busy}
                onClick={handle.run}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
