import { APITypesV1 } from "@cur8/api-client";
import { CheckinSessionState, Patient, Room, Visit } from "@cur8/rich-entity";

export type SessionState = {
  patient?: Patient;
  consents?: APITypesV1.ConsentSignature[];
  room?: Room;
  session?: CheckinSessionState;
  visit?: Visit;
};

export function createInitialSession(): SessionState {
  return {};
}
