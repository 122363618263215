import { APIFetchError } from "@cur8/api-client";
import { useCallback, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useConciergeSessionContext } from "render/context/ConciergeContext";
import { useReporting } from "render/hooks/useReporting";
import { paths } from "render/routes/paths";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "../BailView/BailView";
import { ReactComponent as QRCodeIcon } from "./assets/show-qrcode.svg";
import { useQRCodeURL } from "./hooks/useQRCodeURL";
import styles from "./styles.module.sass";
import { Typography } from "@cur8/maneki";
import {
  extendLocationCookie,
  getAndParseLocationCookie,
} from "lib/nekoCookies";
import type { AuthMethod } from "render/views/JoinSessionView/token";
import { LoginOption } from "render/views/JoinSessionView/components/LoginOption";
import { Link } from "render/ui/trigger/Link";
import { useCreateSessionMutation } from "hooks/mutations/useCreateSessionMutation";
import { useFetchSessionQuery } from "hooks/queries/useFetchSessionQuery";
import { usePatientQuery } from "hooks/queries/usePatientQuery";

interface JoinSessionViewProps {
  goTo(url: string): void;
}

const authMethods: { id: AuthMethod; description: string }[] = [
  { id: "bankid", description: "BankID" },
  { id: "phoneauth", description: "Phone authentication" },
];

export function JoinSessionView({ goTo }: JoinSessionViewProps) {
  const { state, update } = useConciergeSessionContext();

  const { patient } = state;

  const [hasSessionGone, setHasSessionGone] = useState<boolean | undefined>(
    undefined
  );

  const { logError } = useReporting();

  const parsedLocation = getAndParseLocationCookie();

  const [authMethod, setAuthMethod] = useState<AuthMethod>(
    parsedLocation?.siteId.startsWith("SE-") ? "bankid" : "phoneauth"
  );

  const [isTimedOut, setIsTimedOut] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (parsedLocation == null) {
      goTo(paths.locationSelector.url({}));
      return;
    }

    extendLocationCookie(parsedLocation);
  }, [goTo, parsedLocation]);

  const goToNext = useCallback(() => {
    const url = paths.assignSlot.url({});
    goTo(url);
  }, [goTo]);

  const createSessionMutation = useCreateSessionMutation();

  const fetchSessionQuery = useFetchSessionQuery({
    sessionId: createSessionMutation.data?.sessionId,
    options: {
      enabled:
        Boolean(createSessionMutation.data?.sessionId) &&
        !hasSessionGone &&
        !patient,
      refetchInterval: POLL_BACKOFF_TIME,
    },
  });

  const patientQuery = usePatientQuery({
    patientId: fetchSessionQuery.data?.patientId,
    options: {
      enabled: Boolean(fetchSessionQuery.data?.patientId),
    },
  });

  // sync with context state
  useEffect(() => {
    if (fetchSessionQuery.data == null) {
      return;
    }
    update({ session: fetchSessionQuery.data });
  }, [fetchSessionQuery.data, update]);

  useEffect(() => {
    if (patientQuery.data == null) {
      return;
    }
    update({ patient: patientQuery.data });
    goToNext();
  }, [goToNext, patientQuery.data, update]);

  const startCheckIn = useCallback(async () => {
    setIsTimedOut(false);
    // hide QR code after set time
    setTimeout(() => setIsTimedOut(true), QR_CODE_TIMEOUT);

    const seed = createSeed();

    createSessionMutation.mutate(seed, {
      onSuccess: (data) => update({ session: data }),
      onError: (error) => logError(error),
    });
  }, [createSessionMutation, logError, update]);

  const patientDeviceURL = useQRCodeURL(authMethod);

  if (
    fetchSessionQuery.error instanceof APIFetchError &&
    fetchSessionQuery.error.response.status === 404
  ) {
    setHasSessionGone(true);

    return <BailView message="Session gone" />;
  }

  return (
    <PageFrameContent>
      <div className={styles.JoinSessionView}>
        <section className={styles.intro}>
          <h1 className={styles.location}>
            {parsedLocation && parsedLocation.name}
          </h1>
          <div className={styles.paddingContainer}>
            <h2 className={styles.title}>Neko Health</h2>
            <Typography variant="title-s" color="subtle">
              Please point your phone camera at the QR code to check-in.
            </Typography>
          </div>
        </section>

        <section>
          <div className={styles.linksLayout}>
            <div className={styles.qrCode} data-url={patientDeviceURL}>
              <button
                className={styles.blur}
                data-visible={isTimedOut || !createSessionMutation.data}
                onClick={startCheckIn}
              >
                <div className={styles.showcta}>
                  <QRCodeIcon height={40} width={40} />
                  <Typography variant="label-m" as="span">
                    Show code
                  </Typography>
                </div>
              </button>
              <div className={styles.frame}>
                <QRCode
                  value={patientDeviceURL ?? FAKE_QR_ENTROPY}
                  size={228}
                />
              </div>
              <form
                className={styles.form}
                hidden={isTimedOut || !createSessionMutation.data}
                onChange={(event) => {
                  const { elements } = event.currentTarget;
                  const el = elements.namedItem("authmethod") as RadioNodeList;
                  setAuthMethod(el.value as AuthMethod);
                }}
              >
                {authMethods.map(({ id, description }) => (
                  <LoginOption
                    key={id}
                    name="authmethod"
                    value={id}
                    defaultChecked={id === authMethod}
                  >
                    {description}
                  </LoginOption>
                ))}
              </form>
            </div>
            <div className={styles.links}>
              <Link onPress={() => goTo(paths.selectVisit.url({}))}>
                Manual check-in
              </Link>
              <Link onPress={() => goTo(paths.selectActiveVisit.url({}))}>
                Active sessions
              </Link>
            </div>
          </div>
          {patient && (
            <div className={styles.cta}>
              <ActionButton onClick={goToNext}>Continue</ActionButton>
            </div>
          )}
        </section>
      </div>
    </PageFrameContent>
  );
}

const FAKE_QR_ENTROPY = "0129841902481=24g9182418=4gk190gk190gj8m";

const POLL_BACKOFF_TIME = 2000;

const QR_CODE_TIMEOUT = 5 * 60 * 1000;

function createSeed() {
  return (Math.random() * Number.MAX_SAFE_INTEGER).toFixed();
}
