import {
  ChoiceQuestion,
  MultiChoiceQuestion,
  TextQuestion,
} from "@cur8/questionnaire";

import { APITypesV1 } from "@cur8/api-client";
import { DeviceTitle } from "render/views/IncidentReportView/types";

export const HasDeviceIssue = new ChoiceQuestion(
  "Where there any device issues?",
  [
    ["Yes", true],
    ["No", false],
  ]
);

export const HasAdverseEvent = new ChoiceQuestion(
  "Where there any safety incidents?",
  [
    ["Yes", true],
    ["No", false],
  ]
);

export const SeverityGrade = new ChoiceQuestion(
  "How severe was the incident?",
  [
    ["Mild", APITypesV1.Severity.Mild],
    ["Moderate", APITypesV1.Severity.Moderate],
    ["Severe", APITypesV1.Severity.Severe],
    ["Life Threatening", APITypesV1.Severity.LifeThreatening],
  ]
);

export const RelationshipToDevice = new ChoiceQuestion(
  "Was it related to the device?",
  [
    ["Not related", APITypesV1.RelatedProbability.NotRelated],
    ["Possibly related", APITypesV1.RelatedProbability.PossiblyRelated],
    ["Probably related", APITypesV1.RelatedProbability.ProbablyRelated],
    ["Most likely related", APITypesV1.RelatedProbability.MostLikelyRelated],
  ]
);
export const RelationshipToProcedure = new ChoiceQuestion(
  "Was it related to the exam  procedure?",
  [
    ["Not related", APITypesV1.RelatedProbability.NotRelated],
    ["Possibly related", APITypesV1.RelatedProbability.PossiblyRelated],
    ["Probably related", APITypesV1.RelatedProbability.ProbablyRelated],
    ["Most likely related", APITypesV1.RelatedProbability.MostLikelyRelated],
  ]
);

export const MitigationAction = new ChoiceQuestion("What was done?", [
  ["Nothing", APITypesV1.AdverseEventActionTaken.Nothing],
  ["Scan stopped", APITypesV1.AdverseEventActionTaken.ScanStopped],
  [
    "Treatment given to treat AE",
    APITypesV1.AdverseEventActionTaken.TreatmentGivenToAE,
  ],
  [
    "Discontinued from study",
    APITypesV1.AdverseEventActionTaken.DiscontinuedFromStudy,
  ],
]);

export const WhatWasTheOutcome = new ChoiceQuestion("What was the outcome?", [
  ["Resolved", APITypesV1.AdverseEventOutcome.Resolved],
  [
    "Resolved with sequelae",
    APITypesV1.AdverseEventOutcome.ResolvedWithSequelae,
  ],
  ["Ongoing", APITypesV1.AdverseEventOutcome.Ongoing],
  ["Death", APITypesV1.AdverseEventOutcome.Death],
]);

export const DeviceList = new MultiChoiceQuestion(
  "Which devices where there issues with?",
  [
    ["Blood system", APITypesV1.DeviceName.BloodSystem],
    ["Cardio lasers", APITypesV1.DeviceName.CardioLasers],
    ["Cardio radar", APITypesV1.DeviceName.CardioRadar],
    ["Derma app", APITypesV1.DeviceName.DermaApp],
    ["DocUI", APITypesV1.DeviceName.DocUI],
    ["ECG", APITypesV1.DeviceName.Ecg],
    ["Eye pressure", APITypesV1.DeviceName.EyePressure],
    ["Grip strength", APITypesV1.DeviceName.GripStrength],
    ["MESI system", APITypesV1.DeviceName.MesiSystem],
    ["POX", APITypesV1.DeviceName.Pox],
    ["Scale", APITypesV1.DeviceName.Scale],
    ["Skin system", APITypesV1.DeviceName.SkinSystem],
    ["Tissue system", APITypesV1.DeviceName.TissueSystem],
  ]
);

const createDeviceIssueDescriptionQuestion = (
  device: APITypesV1.DeviceName
) => {
  const deviceTitle = DeviceTitle[device];
  return new TextQuestion(`What was the issue with the ${deviceTitle}?`);
};

export const CardioLasersIssueDescription =
  createDeviceIssueDescriptionQuestion(APITypesV1.DeviceName.CardioLasers);
export const CardioRadarIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypesV1.DeviceName.CardioRadar
);
export const ECGIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypesV1.DeviceName.Ecg
);
export const MESIIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypesV1.DeviceName.MesiSystem
);
export const BloodSystemIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypesV1.DeviceName.BloodSystem
);
export const DermaAppIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypesV1.DeviceName.DermaApp
);
export const DocUIIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypesV1.DeviceName.DocUI
);
export const EyePressureIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypesV1.DeviceName.EyePressure
);
export const GripStrengthIssueDescription =
  createDeviceIssueDescriptionQuestion(APITypesV1.DeviceName.GripStrength);
export const POXIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypesV1.DeviceName.Pox
);
export const TissueSystemIssueDescription =
  createDeviceIssueDescriptionQuestion(APITypesV1.DeviceName.TissueSystem);
export const SkinSystemIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypesV1.DeviceName.SkinSystem
);
export const ScaleIssueDescription = createDeviceIssueDescriptionQuestion(
  APITypesV1.DeviceName.Scale
);

const createDeviceReasonQuestion = () =>
  new ChoiceQuestion(`Possible reason for the device issue?`, [
    ["Device malfunction", APITypesV1.PossibleReason.DeviceMalfunction],
    [
      "Operational issue or bug",
      APITypesV1.PossibleReason.OperationalIssueOrBug,
    ],
    ["Poor instructions", APITypesV1.PossibleReason.PoorInstructions],
  ]);

const createDeviceCategoryQuestion = () =>
  new ChoiceQuestion(`How would you categorize the issue?`, [
    [
      "Poor performance (speed, accuracy)",
      APITypesV1.IssueCategory.PoorPerformance,
    ],
    ["Unreliability", APITypesV1.IssueCategory.Unreliability],
    ["Poor quality", APITypesV1.IssueCategory.PoorQuality],
    ["Poor durability (worn out)", APITypesV1.IssueCategory.PoorDurability],
    ["Lack of safety", APITypesV1.IssueCategory.LackOfSafety],
    ["Identity (incorrect device)", APITypesV1.IssueCategory.Identity],
  ]);

const createDeviceWhatWasDoneQuestion = () =>
  new ChoiceQuestion("What was done?", [
    ["Nothing (exam continued)", APITypesV1.IncidentReportAction.Nothing],
    ["Skipped (exam discontinued)", APITypesV1.IncidentReportAction.Skipped],
    ["Other", APITypesV1.IncidentReportAction.Other],
  ]);

const createDeviceIssueLeadToHealthIssues = () =>
  new ChoiceQuestion("Could the issue have lead to a serious health risk?", [
    ["Yes", true],
    ["No", false],
  ]);

const createWhatWasDoneOtherDetailsQuestion = () => new TextQuestion("Other");

export const CardioLasersReasonOfTheIssue = createDeviceReasonQuestion();
export const CardioLasersCategoryOfTheIssue = createDeviceCategoryQuestion();
export const CardioLasersWhatWasDone = createDeviceWhatWasDoneQuestion();
export const CardioLasersIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const CardioLasersWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const CardioRadarReasonOfTheIssue = createDeviceReasonQuestion();
export const CardioRadarCategoryOfTheIssue = createDeviceCategoryQuestion();
export const CardioRadarWhatWasDone = createDeviceWhatWasDoneQuestion();
export const CardioRadarIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const CardioRadarWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const ECGReasonOfTheIssue = createDeviceReasonQuestion();
export const ECGCategoryOfTheIssue = createDeviceCategoryQuestion();
export const ECGWhatWasDone = createDeviceWhatWasDoneQuestion();
export const ECGIssueLeadToHealthIssues = createDeviceIssueLeadToHealthIssues();
export const ECGWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const MESIReasonOfTheIssue = createDeviceReasonQuestion();
export const MESICategoryOfTheIssue = createDeviceCategoryQuestion();
export const MESIWhatWasDone = createDeviceWhatWasDoneQuestion();
export const MESIIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const MESIWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const BloodSystemReasonOfTheIssue = createDeviceReasonQuestion();
export const BloodSystemCategoryOfTheIssue = createDeviceCategoryQuestion();
export const BloodSystemWhatWasDone = createDeviceWhatWasDoneQuestion();
export const BloodSystemIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const BloodSystemWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const DermaAppReasonOfTheIssue = createDeviceReasonQuestion();
export const DermaAppCategoryOfTheIssue = createDeviceCategoryQuestion();
export const DermaAppWhatWasDone = createDeviceWhatWasDoneQuestion();
export const DermaAppIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const DermaAppWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const DocUIReasonOfTheIssue = createDeviceReasonQuestion();
export const DocUICategoryOfTheIssue = createDeviceCategoryQuestion();
export const DocUIWhatWasDone = createDeviceWhatWasDoneQuestion();
export const DocUIIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const DocUIWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const EyePressureReasonOfTheIssue = createDeviceReasonQuestion();
export const EyePressureCategoryOfTheIssue = createDeviceCategoryQuestion();
export const EyePressureWhatWasDone = createDeviceWhatWasDoneQuestion();
export const EyePressureIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const EyePressureWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const GripStrengthReasonOfTheIssue = createDeviceReasonQuestion();
export const GripStrengthCategoryOfTheIssue = createDeviceCategoryQuestion();
export const GripStrengthWhatWasDone = createDeviceWhatWasDoneQuestion();
export const GripStrengthIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const GripStrengthWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const POXReasonOfTheIssue = createDeviceReasonQuestion();
export const POXCategoryOfTheIssue = createDeviceCategoryQuestion();
export const POXWhatWasDone = createDeviceWhatWasDoneQuestion();
export const POXIssueLeadToHealthIssues = createDeviceIssueLeadToHealthIssues();
export const POXWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const ScaleReasonOfTheIssue = createDeviceReasonQuestion();
export const ScaleCategoryOfTheIssue = createDeviceCategoryQuestion();
export const ScaleWhatWasDone = createDeviceWhatWasDoneQuestion();
export const ScaleIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const ScaleWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const SkinSystemReasonOfTheIssue = createDeviceReasonQuestion();
export const SkinSystemCategoryOfTheIssue = createDeviceCategoryQuestion();
export const SkinSystemWhatWasDone = createDeviceWhatWasDoneQuestion();
export const SkinSystemIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const SkinSystemWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();

export const TissueSystemReasonOfTheIssue = createDeviceReasonQuestion();
export const TissueSystemCategoryOfTheIssue = createDeviceCategoryQuestion();
export const TissueSystemWhatWasDone = createDeviceWhatWasDoneQuestion();
export const TissueSystemIssueLeadToHealthIssues =
  createDeviceIssueLeadToHealthIssues();
export const TissueSystemWhatWasDoneOtherDetails =
  createWhatWasDoneOtherDetailsQuestion();
