import { fromAPI } from "@cur8/rich-entity";
import { useQueries, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

export function usePatientQueries(data: Array<string | undefined> | undefined) {
  const apiClient = useAPIClient();

  return useQueries({
    queries: data
      ? data.map((patientId) => {
          return {
            queryKey: queryKey(patientId),
            queryFn: queryFn(apiClient, patientId),
          };
        })
      : [],
  });
}

export function usePatientQuery<T = Body>({
  patientId,
  options = {},
}: {
  patientId: string | undefined;
  options?: QueryOptions<Body, Key, T>;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(patientId),
    queryFn: queryFn(apiClient, patientId),
    staleTime: Infinity,
    ...options,
  });
}

const queryKey = (patientId: string | undefined) => ["patient", patientId];
const queryFn =
  (apiClient: APIClient, patientId: string | undefined) => async () => {
    if (patientId == null) {
      return Promise.reject("No patientId");
    }
    const patientDTO = await apiClient.patient.fetchPatient({ patientId })
      .result;
    const result = fromAPI.toPatientDetails(patientDTO);

    return result;
  };

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;
