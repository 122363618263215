import { fromAPI } from "@cur8/rich-entity";
import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useTodaysScheduleQuery({
  siteId,
}: {
  siteId: string | undefined;
}) {
  const apiClient = useAPIClient();

  const today = useMemo(() => {
    return DateTime.now();
  }, []);

  const tomorrow = useMemo(() => {
    return DateTime.now().plus({ days: 1 });
  }, []);

  const start = today.startOf("day").toISO();
  const end = tomorrow.startOf("day").toISO();

  return useQuery({
    queryKey: queryKey(siteId),
    queryFn:
      siteId && start && end
        ? queryFn(apiClient, siteId, start, end)
        : skipToken,
    enabled: Boolean(siteId),
    select: (rooms) => {
      const combinedSlots = rooms.map((room) => room.slots).flat();
      const filteredSlots = combinedSlots.filter(
        (slot) => slot?.isBooked == true
      );
      const bookedSlotIds = filteredSlots.map((slot) => ({
        visitId: slot?.id.split("_")[1],
        patientId: slot?.patientId,
      }));
      return bookedSlotIds;
    },
  });
}

function queryFn(
  apiClient: APIClient,
  siteId: string,
  start: string,
  end: string
) {
  return async function () {
    const scheduleDTO = await apiClient.booking.getScheduleViewForSite(
      {
        siteId,
      },
      { start, end }
    ).result;

    const result = scheduleDTO.items.map(fromAPI.toRoomWithScheduleViewSlot);

    return result;
  };
}

function queryKey(siteId: string | undefined) {
  return ["schedule", siteId] as const;
}
