import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = () => ["sites"];
const queryFn = (apiClient: APIClient) => async () => {
  return await apiClient.site.querySites().result;
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useSitesQuery<T = Body>({
  options = {},
}: {
  options?: QueryOptions<Body, Key, T>;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(),
    queryFn: queryFn(apiClient),
    staleTime: Infinity,
    ...options,
  });
}

useSitesQuery.queryKey = queryKey;
useSitesQuery.queryFn = queryFn;
