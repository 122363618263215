import { APITypesV1 } from "@cur8/api-client";

export const DevicePMID: { [key in APITypesV1.DeviceName]?: string } = {
  [APITypesV1.DeviceName.SkinSystem]: "100355",
  [APITypesV1.DeviceName.TissueSystem]: "100515",
  [APITypesV1.DeviceName.CardioLasers]: "100596",
  [APITypesV1.DeviceName.CardioRadar]: "100596",
};

export const DeviceTitle: { [key in APITypesV1.DeviceName]: string } = {
  [APITypesV1.DeviceName.SkinSystem]: "Skin system",
  [APITypesV1.DeviceName.TissueSystem]: "Tissue system",
  [APITypesV1.DeviceName.CardioLasers]: "Cardio lasers",
  [APITypesV1.DeviceName.CardioRadar]: "Cardio radar",
  [APITypesV1.DeviceName.BloodSystem]: "Blood system",
  [APITypesV1.DeviceName.DermaApp]: "Derma app",
  [APITypesV1.DeviceName.DocUI]: "DocUI",
  [APITypesV1.DeviceName.Ecg]: "ECG",
  [APITypesV1.DeviceName.Scale]: "Scale",
  [APITypesV1.DeviceName.Pox]: "POX",
  [APITypesV1.DeviceName.MesiSystem]: "MESI system",
  [APITypesV1.DeviceName.GripStrength]: "Grip strength",
  [APITypesV1.DeviceName.EyePressure]: "Eye pressure",
  [APITypesV1.DeviceName.CardioSystem]: "Cardio system",
};

export interface AdverseEventReport {
  severity: APITypesV1.Severity;
  deviceRelated: APITypesV1.RelatedProbability;
  examProcedureRelated: APITypesV1.RelatedProbability;
  actionTaken: APITypesV1.AdverseEventActionTaken;
  outcome: APITypesV1.AdverseEventOutcome;
  sentToAuthorities: boolean;
}

export interface DeviceDeficiency {
  device: {
    deviceName: APITypesV1.DeviceName;
    id?: string | null;
  };
  description: string;
  possibleReason: APITypesV1.PossibleReason;
  issueCategory: APITypesV1.IssueCategory;
  actionTaken: {
    action: APITypesV1.IncidentReportAction;
    other?: string | null;
  };
  ledToHealthRisk: boolean;
  sentToAuthorities: boolean;
}

export interface IncidentReport {
  deviceDeficiencies: DeviceDeficiency[];
  adverseEventReport?: AdverseEventReport;
  visitId: string;
  patientId: string;
  eTag: string | null;
}
